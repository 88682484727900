<gentext-chat-ui-chat
  [suggestions]="(chatSuggestions$ | async) || []"
  (managePlanClicked)="managePlan()"
  (userMessageSent)="userMessageSent()"
  [addOrUpdateMessage$]="addOrUpdateMessage$"
  [clearMessages$]="clearMessages$"
  [error$]="error$"
  productName="GenText"
  [documentSummaryEnabled]="true"
  [enableFootnotes]="true"
></gentext-chat-ui-chat>
