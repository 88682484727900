import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '@gentext/auth-office';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'gentext-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class HomeComponent implements OnInit {
  selectedLanguageOption = 'en';

  authenticated$ = this.authService.authState$.pipe(map((a) => !!a));

  isLoadingAuth$ = this.authService.loading$;

  constructor(
    private authService: AuthService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.translocoService.langChanges$
      .pipe(untilDestroyed(this))
      .subscribe((l) => (this.selectedLanguageOption = l));
  }
  async signIn() {
    this.authService.refreshState(this.selectedLanguageOption);
  }

  support() {
    const link = `mailto:support@gentext.ai`;
    window.open(link);
  }
}
