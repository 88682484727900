import { Injectable } from '@angular/core';

import { translate, TranslocoService } from '@jsverse/transloco';
import { firstValueFrom } from 'rxjs';
import * as CookieConsent from 'vanilla-cookieconsent';
@Injectable()
export class CookieConsentService {
  constructor(private translateService: TranslocoService) {}
  async init() {
    await this.loadAllAvailableLanguages();
    this.initCookieConsent();
    this.translateService.langChanges$.subscribe((l) => {
      CookieConsent.setLanguage(l);
    });
  }

  private getTranslation(lang: string): CookieConsent.Translation {
    return {
      consentModal: {
        title: translate('Cookies preferences', {}, lang),
        description: `${translate('We use cookies to enhance your experience on our website', {}, lang)}, ${translate('analyze site traffic', {}, lang)}, ${translate('and provide you with personalized content', {}, lang)}. ${translate('By clicking "Accept All," you consent to our use of cookies', {}, lang)}. ${translate('You can also manage your preferences by clicking "Manage Individual preferences"', {}, lang)}.`,
        acceptAllBtn: translate('Accept all', {}, lang),
        acceptNecessaryBtn: undefined,
        showPreferencesBtn: translate(
          'Manage Individual preferences',
          {},
          lang,
        ),
      },
      preferencesModal: {
        title: translate('Manage cookie preferences', {}, lang),
        acceptAllBtn: translate('Accept all', {}, lang),
        acceptNecessaryBtn: translate('Accept necessary', {}, lang),
        savePreferencesBtn: translate('Accept current selection', {}, lang),
        closeIconLabel: translate('Close modal', {}, lang),
        sections: [
          {
            title: translate('Strictly Necessary cookies', {}, lang),
            description: `${translate('These cookies are essential for the proper functioning of the app and cannot be disabled', {}, lang)}.`,
            linkedCategory: 'necessary',
          },
          {
            title: translate('Performance and Analytics', {}, lang),
            description: `${translate('These cookies collect information about how you use our app', {}, lang)}.`,
            linkedCategory: 'analytics',
          },
        ],
      },
    };
  }

  private async loadAllAvailableLanguages() {
    // e.g. CookieConsent needs all translations loaded up front
    const languages = this.translateService.getAvailableLangs();
    for (let i = 0; i < languages.length; i++) {
      const language = languages[i].toString();
      await firstValueFrom(this.translateService.load(language));
    }
  }
  private initCookieConsent() {
    const languages = this.translateService.getAvailableLangs() as string[];
    const translations: { [key: string]: CookieConsent.Translation } = {};
    for (const lang of languages) {
      translations[lang] = this.getTranslation(lang);
    }

    CookieConsent.run({
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          // this category cannot be disabled
        },
        analytics: {},
      },

      language: {
        default: 'en',
        translations,
      },

      onConsent: ({ cookie }) => {
        const hasAnalytics = cookie.categories.includes('analytics');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const clarity = (window as any)['clarity'];
        if (clarity) {
          if (hasAnalytics) {
            clarity('consent');
          } else {
            clarity('consent', false);
          }
        }
      },
    });
  }
}
