import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'gentext-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class RedirectComponent implements OnInit {
  ngOnInit() {
    const url = new URL(location.href).searchParams.get('url');
    if (!url) {
      return;
    }
    window.location.href = decodeURIComponent(url);
  }
}
