import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { CookieConsentService } from './cookie-consent/cookie-consent.service';

@NgModule({
  imports: [CommonModule, TranslocoModule],
  providers: [CookieConsentService],
  exports: [],
})
export class CookieConsentModule {}
