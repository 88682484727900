/* eslint-disable @typescript-eslint/no-var-requires */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '@gentext/config';
import { TranslocoService, translate } from '@jsverse/transloco';
import { Observable, map } from 'rxjs';
import { MenuOption, getMenuOptions } from '../menu-options';

const chevronDownIcon = require('@fluentui/svg-icons/icons/chevron_down_20_filled.svg');
@Component({
  selector: 'gentext-feature-selection',
  styleUrls: ['./feature-selection.component.css'],
  templateUrl: './feature-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FeatureSelectionComponent implements OnInit {
  chevronDown = chevronDownIcon;

  config$ = this.config.config$;
  menuOptions$ = this.translocoService.langChanges$.pipe(
    map(() => getMenuOptions()),
  );

  selectedOptionPath = '';
  showOptions = true;

  selectedOptionPathChange(value: string) {
    this.setSelectedOptionPath(value);
  }

  getOptionTitle$(path: string): Observable<string> {
    return this.menuOptions$.pipe(
      map((options) => {
        const option = options.find((o) => o.path === path);
        return option != null ? translate(option.titleKey) : '';
      }),
    );
  }

  getIconSrc(menuOption: MenuOption) {
    const base = 'assets/icons';
    switch (menuOption.path) {
      case 'generate-draft':
        return `${base}/generate.svg`;
      case 'generate-summary':
        return `${base}/summarize.svg`;
      case 'rephrase':
        return `${base}/rephrase.svg`;
      case 'generate-papers':
        return `${base}/citate.svg`;
      case 'generate-research-QA':
        return `${base}/search.svg`;
    }
    return '';
  }

  ngOnInit() {
    this.setSelectedOptionPath(window.location.pathname.substring(1));
  }

  private setSelectedOptionPath(value: string) {
    this.selectedOptionPath = value;
    if (this.selectedOptionPath && this.selectedOptionPath !== 'home') {
      this.showOptions = false;
    } else {
      this.showOptions = true;
    }

    this.router.navigateByUrl(this.selectedOptionPath);
  }

  constructor(
    private config: ConfigService,
    private router: Router,
    private translocoService: TranslocoService,
  ) {}
}
