import { Inject, Injectable } from '@angular/core';
import MarkdownIt from 'markdown-it';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReferenceDto } from '@gentext/api-client';
import { mml2omml } from 'mathml2omml';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MarkdownItTexMath from 'markdown-it-texmath';
import {
  GET_DEFAULT_ERROR_MESSAGE,
  GetDefaultErrorMessage,
} from './get-default-error-message';

@Injectable({ providedIn: 'root' })
export class OfficeHelperService {
  private readonly markdownIt: MarkdownIt;
  constructor(
    @Inject(GET_DEFAULT_ERROR_MESSAGE)
    private getDefaultErrorMessage: GetDefaultErrorMessage,
  ) {
    this.markdownIt = new MarkdownIt({
      html: true,
    }).use(MarkdownItTexMath, {
      engine: require('katex'),
      delimiters: 'dollars',
      katexOptions: {
        output: 'mathml',
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getErrorMessage(e: any) {
    let message = this.getDefaultErrorMessage(e);
    if (e?.message === 'GeneralException') {
      message = 'Something went wrong. Is the document perhaps read-only?';
    } else if (e?.message === 'InvalidArgument') {
      message =
        'Something went wrong generating text. Please try again with a different selection.';
    } else if (typeof e.error === 'string') {
      message = e.error;
    } else if (e.message) {
      message = e.message;
    }
    return message;
  }
  getHtmlFromMarkdown(markdown: string) {
    // replace all markers used by GPT: $, <latex>, \[ \], \( with $$ as the markdown-it-texmath plugin uses that
    // also, remove any white space or new lines before or after the markers
    const dollarDelimited = markdown
      .replace(/\\\$[\n\r\s]*/g, '$$')
      .replace(/<latex>[\n\r\s]*|[\n\r\s]*<\/latex>/g, '$$')
      .replace(/\\\[[\n\r\s]*|[\n\r\s]*\\\]/g, '$$')
      .replace(/\\\([\n\r\s]*|[\n\r\s]*\\\)/g, '$$');
    return this.markdownIt.render(dollarDelimited);
  }
  insertMarkdown(
    markdown: string,
    paragraph?: Word.Paragraph,
    references: ReferenceDto[] = [],
    enableFootnotes = false,
  ) {
    console.log({ enableFootnotes });
    return Word.run(async (context) => {
      const html = this.getHtmlFromMarkdown(markdown);
      const doc = context.document;

      let range: Word.Range;
      if (paragraph === undefined) {
        range = doc.getSelection();
        range.clear();
      } else {
        range = paragraph.getRange();
      }

      await context.sync();
      const generatedRanges = [];
      // Check if the HTML contains any math elements
      if (html.indexOf('<math') === -1) {
        // If there are no math elements, insert the HTML as is
        range = range.insertHtml(html, 'End');
        generatedRanges.push(range);
        await context.sync();
      } else {
        // If there are math elements, process them separately
        await context.sync();

        let index = 0;
        let run = true;

        while (run) {
          const nextMathIndex = html.indexOf('<math', index);
          const nextMathEndIndex = html.indexOf('</math>', index);
          const subHtml = html.substring(
            index,
            nextMathIndex > 0 ? nextMathIndex : html.length,
          );
          if (subHtml !== '') {
            range = range.insertHtml(`${subHtml}`, 'End');
            generatedRanges.push(range);
            range = range.insertText(' ', 'After');
          }
          await context.sync();
          if (nextMathIndex > 0 && nextMathEndIndex > 0) {
            const originalMath = html.substring(
              nextMathIndex,
              nextMathEndIndex + 7,
            );
            const formattedMath = originalMath.replace(
              /<annotation.*?<\/annotation>/g,
              '',
            );

            // Convert the formatted math to OMML using mml2omml function
            const omml = mml2omml(formattedMath);

            // Generate the package XML using the OMML
            const packageXml = this.getPackageXml(omml);

            // Insert the package XML as OOXML
            range = range.insertOoxml(packageXml, 'End');
            await context.sync();
          }
          if (nextMathEndIndex === -1) {
            run = false;
          }
          index = nextMathEndIndex + 7;
        }
      }
      if (enableFootnotes) {
        // TODO below does not work when an existing paragraph is passed in, currently
        // only from GenText non-chat
        for (let i = 0; i < generatedRanges.length; i++) {
          const range = generatedRanges[i];
          const linkRanges = range.getHyperlinkRanges();
          context.load(linkRanges, 'items');
          await context.sync();
          for (let j = 0; j < linkRanges.items.length; j++) {
            const linkRange = linkRanges.items[j];
            const url = linkRange.hyperlink;
            let reference = references.find((r) => r.url === url);
            if (reference) {
              await this.insertFootnote(reference, linkRange);
            } else {
              reference = {
                title: linkRange.text,
                url: linkRange.hyperlink,
              };
              await this.insertFootnote(reference, linkRange);
            }
          }
        }
      }

      // TODO when no paragraph was passed in, the else branch above was executed
      // however, that will only return the last generated range
      // should return generatedRanges instead
      return range;
    });
  }

  private async insertFootnote(reference: ReferenceDto, range: Word.Range) {
    console.log({ reference });
    const context = range.context;

    const footnote = range.insertFootnote('');
    context.trackedObjects.add(footnote);
    const footnoteBody = footnote.load('body');
    await context.sync();

    const firstParagraph = footnoteBody.body.paragraphs.getFirst();

    if (reference.authors) {
      const authors = reference.authors
        .map((a) => `${a.lastName}, ${a.initials}`)
        .join(', ');
      const authorsRange = firstParagraph.insertText(
        `${authors} (${reference.year})`,
        'End',
      );
      authorsRange.font.italic = false;
    }

    const titleRange = firstParagraph.insertText(` ${reference.title}`, 'End');
    titleRange.font.italic = false;

    titleRange.hyperlink = reference.url;

    if (reference.journal) {
      const journalText = [reference.journal.name, reference.journal.volume]
        .filter((t) => !!t)
        .join(' ');

      const journalRange = firstParagraph.insertText(` ${journalText}`, 'End');
      journalRange.font.italic = true;
      if (reference.journal.pages) {
        const pagesRange = firstParagraph.insertText(
          `, ${reference.journal.pages}`,
          'End',
        );
        pagesRange.font.italic = false;
      }
    }
    await context.sync();
  }
  private getPackageXml(ooxml: string) {
    const packageXml = `<pkg:package xmlns:pkg="http://schemas.microsoft.com/office/2006/xmlPackage">
  <pkg:part pkg:name="/_rels/.rels" pkg:contentType="application/vnd.openxmlformats-package.relationships+xml" pkg:padding="512">
    <pkg:xmlData>
      <Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">
        <Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument" Target="word/document.xml"/>
      </Relationships>
    </pkg:xmlData>
  </pkg:part>
  <pkg:part pkg:name="/word/document.xml" pkg:contentType="application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml">
    <pkg:xmlData>
      <w:document   
  xmlns:m="http://schemas.openxmlformats.org/officeDocument/2006/math"
  xmlns:w="http://schemas.openxmlformats.org/wordprocessingml/2006/main">
  <w:body>    
    <w:p>      
      ${ooxml}
    </w:p>    
  </w:body>
</w:document>
    </pkg:xmlData>
  </pkg:part>
 </pkg:package>
`;
    return packageXml;
  }
  insertText(aiText: string) {
    return Word.run(async (context) => {
      const doc = context.document;
      const range = doc.getSelection();
      range.clear();
      await context.sync();
      const regex = /\*\*(.*?)\*\*/g;
      let lastIndex = 0;
      let match;

      while ((match = regex.exec(aiText)) !== null) {
        const normalText = aiText.substring(lastIndex, match.index);
        if (normalText) {
          const normalRange = range.insertText(normalText, 'End');
          normalRange.font.bold = false;
        }
        const boldText = match[1];
        if (boldText) {
          const boldRange = range.insertText(boldText, 'End');
          boldRange.font.bold = true;
        }
        lastIndex = regex.lastIndex;
      }
      if (lastIndex < aiText.length) {
        const remainingText = aiText.substring(lastIndex);
        const normalRange = range.insertText(remainingText, 'End');
        normalRange.font.bold = false;
      }
      await context.sync();
      return '';
    }).catch((error) => {
      return this.getErrorMessage(error);
    });
  }
}
