import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FeatureFlagService,
  LicenseDto,
  PreferencesService,
} from '@gentext/api-client';
import { ChatMessage } from '@gentext/chat-ui';
import { LoggingService } from '@gentext/logging';
import { TranslocoService, translate } from '@jsverse/transloco';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, firstValueFrom, map, take } from 'rxjs';
import { ChatUiService } from '../chat-ui.service';
import { DocumentService } from '../document.service';
import { LicenseService } from '../license.service';

const NO_STORE_REVIEW_PREFERENCE = 'noStoreReview';
@UntilDestroy()
@Component({
  selector: 'gentext-chat-ui',
  templateUrl: './chat-ui.component.html',
  styleUrl: './chat-ui.component.css',
  standalone: false,
})
export class ChatUiComponent implements OnInit {
  addOrUpdateMessage$ = this.chatService.addOrUpdateMessage$;
  clearMessages$ = this.chatService.clearMessages$;
  error$ = this.chatService.error$;

  /**
   *  Below is needed for i18n:extract, see https://github.com/jsverse/transloco-keys-manager?tab=readme-ov-file#dynamic-keys
   *
   *  t(Explain how the human immune system functions)
   *  t(Describe the laws of motion formulated by Isaac Newton)
   *  t(What is the periodic table, and how is it organized)
   *  t(Illustrate the importance of the Pythagorean theorem)
   *  t(What were the main causes of the Industrial Revolution?)
   *  t(Analyze the themes present in Shakespeare's "Hamlet")
   *  t(Describe the factors that influence climate change)
   *  t(Explain the concept of supply and demand)
   *  t(What is the significance of algorithms in programming?)
   *  t(Examine the impact of the Renaissance on European art and culture)
   *
   */

  allChatSuggestions$ = combineLatest(
    [
      'Explain how the human immune system functions',
      'Describe the laws of motion formulated by Isaac Newton',
      'What is the periodic table, and how is it organized',
      'Illustrate the importance of the Pythagorean theorem',
      'What were the main causes of the Industrial Revolution?',
      'Analyze the themes present in Shakespeare\'s "Hamlet"',
      'Describe the factors that influence climate change',
      'Explain the concept of supply and demand',
      'What is the significance of algorithms in programming?',
      'Examine the impact of the Renaissance on European art and culture',
    ].map((text) => {
      const commaSeparated = text.split(', ');

      const result$ = commaSeparated.map((c) =>
        this.translocoService.selectTranslate<string>(c),
      );

      return combineLatest(result$).pipe(map((r) => r.join(', ')));
    }),
  );
  chatSuggestions$ = this.allChatSuggestions$.pipe(
    map((suggestions) => {
      return suggestions.sort(() => Math.random() - 0.5).slice(0, 3);
    }),
    map((suggestions) => suggestions.map((text) => ({ text }))),
  );

  private _startLicense: LicenseDto | undefined;
  private _userMessageCount = 0;

  // start at true to prevent showing the store review message inadvertently before loading preferences
  private _preferenceNoStoreReview = true;
  private _showStoreReviewAfterMessage = Math.floor(Math.random() * 7 + 6);

  constructor(
    private router: Router,
    private translocoService: TranslocoService,
    private chatService: ChatUiService,
    private documentService: DocumentService,
    private featureFlagService: FeatureFlagService,
    private logging: LoggingService,
    private licenseService: LicenseService,
    private preferencesService: PreferencesService,
  ) {}

  managePlan() {
    this.router.navigateByUrl('/chat/manage-plan');
  }

  ngOnInit() {
    this.setupListenerDocSelChanged();
    this.setupListenerStoreReview();
    this.preferencesService.preferencesGet().subscribe((preferences) => {
      const hasPreference = Object.prototype.hasOwnProperty.call(
        preferences.booleanPreferences,
        NO_STORE_REVIEW_PREFERENCE,
      );
      if (hasPreference) {
        this._preferenceNoStoreReview =
          preferences.booleanPreferences[NO_STORE_REVIEW_PREFERENCE];
      } else {
        this._preferenceNoStoreReview = false;
      }
    });
  }
  userMessageSent() {
    this._userMessageCount++;
    if (this._userMessageCount >= this._showStoreReviewAfterMessage) {
      window.setTimeout(() => {
        this.showStoreReviewMessage();
      }, 30000);

      this._showStoreReviewAfterMessage = Math.floor(Math.random() * 7 + 6);
      this._userMessageCount = 0;
    }
  }

  private setupListenerStoreReview() {
    this.licenseService.license$
      .pipe(untilDestroyed(this))
      .subscribe((license) => {
        if (this._startLicense === undefined) {
          this._startLicense = license;
        }
        if (
          license?.planId !== 'free' &&
          (license?.planId !== this._startLicense?.planId ||
            license?.planTerm !== this._startLicense?.planTerm)
        ) {
          this.showStoreReviewMessage();
        }
      });
  }
  private showStoreReviewMessage() {
    if (this._preferenceNoStoreReview) {
      this.logging.trace({
        message: 'Not showing store review message due to preference',
        severityLevel: SeverityLevel.Information,
      });
      return;
    }
    this.logging.trace({
      message: 'Showing store review message',
      severityLevel: SeverityLevel.Information,
    });
    const message: ChatMessage = {
      id: 'store_review',
      text:
        translate(
          'Would you like to leave a review for GenText? Your feedback is important to us',
        ) + '.',
      sender: 'ai',
      isCompleted: true,
      showInsertInDocument: false,
      messageActions: [
        {
          text: translate('Yes'),
          onClick: () => {
            window.open(
              'https://appsource.microsoft.com/en-nz/product/office/WA200005287?tab=Reviews',
            );
            this.chatService.addOrUpdateMessage({
              id: 'store_review',
              text: translate('Thank you for considering') + '.',
              sender: 'ai',
              isCompleted: true,
              showInsertInDocument: false,
            });
          },
        },
        {
          text: translate('Maybe later'),
          onClick: () => {
            this.chatService.addOrUpdateMessage({
              id: 'store_review',
              text: translate('Thank you for considering') + '.',
              sender: 'ai',
              isCompleted: true,
              showInsertInDocument: false,
            });
          },
        },
        {
          text: translate("No, and don't remind me again") + '.',
          onClick: () => {
            this._preferenceNoStoreReview = true;
            this.preferencesService
              .preferencesGet()
              .subscribe((preferences) => {
                preferences.booleanPreferences[NO_STORE_REVIEW_PREFERENCE] =
                  true;
                this.preferencesService
                  .preferencesPost(preferences)
                  .subscribe();
              });

            this.chatService.addOrUpdateMessage({
              id: 'store_review',
              text: translate('Thank you for considering') + '.',
              sender: 'ai',
              isCompleted: true,
              showInsertInDocument: false,
            });
          },
        },
      ],
    };
    this.chatService.addOrUpdateMessage(message);
  }

  private async setupListenerDocSelChanged() {
    const isFeatureEnabled = await firstValueFrom(
      this.featureFlagService.featureFlagGet('rephrase_or_cite').pipe(take(1)),
    );
    if (!isFeatureEnabled.isEnabled) {
      this.logging.trace({
        message: 'Feature rephrase_or_cite is disabled',
        severityLevel: SeverityLevel.Information,
      });
      return;
    }
    this.logging.trace({
      message: 'Feature rephrase_or_cite is enabled',
      severityLevel: SeverityLevel.Information,
    });

    Office.context.document.addHandlerAsync(
      Office.EventType.DocumentSelectionChanged,
      () => {
        Office.context.document.getSelectedDataAsync(
          Office.CoercionType.Text,
          (asyncResult) => {
            if (
              asyncResult.status !== Office.AsyncResultStatus.Failed &&
              asyncResult.value !== ''
            ) {
              const message: ChatMessage = {
                id: 'rephrase_or_cite',
                text: translate(
                  'Would you like to rephrase the selected text or find citations?',
                ),
                sender: 'ai',
                isCompleted: true,
                showInsertInDocument: false,
                messageActions: [
                  {
                    text: translate('Rephrase'),
                    onClick: async () => {
                      try {
                        message.isCompleted = false;
                        this.chatService.addOrUpdateMessage(message);
                        await this.documentService.rephrase();
                        message.isCompleted = true;
                        this.chatService.addOrUpdateMessage(message);
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      } catch (e: any) {
                        this.chatService.setError(e);
                        message.isCompleted = true;
                        this.chatService.addOrUpdateMessage(message);
                      }
                    },
                  },
                  {
                    text: translate('Find citations'),
                    onClick: async () => {
                      try {
                        message.isCompleted = false;
                        this.chatService.addOrUpdateMessage(message);
                        await this.documentService.findCitations();
                        message.isCompleted = true;
                        this.chatService.addOrUpdateMessage(message);
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      } catch (e: any) {
                        this.chatService.setError(e);
                        message.isCompleted = true;
                        this.chatService.addOrUpdateMessage(message);
                      }
                    },
                  },
                ],
              };
              this.chatService.addOrUpdateMessage(message);
            }
          },
        );
      },
    );
  }
}
