import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'gentext-chat-manage-plan',
  templateUrl: './chat-manage-plan.component.html',
  styleUrl: './chat-manage-plan.component.css',
  standalone: false,
})
export class ChatManagePlanComponent {
  constructor(private router: Router) {}
  goBack() {
    this.router.navigateByUrl('/chat');
  }
}
