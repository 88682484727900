import { Observable } from 'rxjs';
import { ChatResponse } from './models';

export interface ChatService {
  sendChat(input: string, chatId: string): Observable<ChatResponse>;
  uploadDocument(file: File, chatId: string): Observable<string>;
  handleFileInputClick?(): Promise<boolean>;
  updateDocumentSummary(chatId: string, summary: string): void;
}

export const CHAT_SERVICE = 'CHAT_SERVICE';
