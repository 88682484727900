import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConfigDto, ConfigService } from '@gentext/config';

import { PaperSearchResponseDto } from '@gentext/api-client';
import { LoggingService } from '@gentext/logging';
import { OfficeHelperService } from '@gentext/office';
import { OpenAIApiService } from '@gentext/openai';
import { PaperProcessingService } from '@gentext/papers';
import { translate } from '@jsverse/transloco';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DocumentService } from '../document.service';

@UntilDestroy()
@Component({
  selector: 'gentext-generate-papers',
  templateUrl: './generate-papers.component.html',
  styleUrls: ['./generate-papers.component.css'],
  standalone: false,
})
export class GeneratePapersComponent implements OnInit {
  error = '';
  isLoading = false;
  hasGenerated = false;
  ignoreDocSelChange = false;
  config: ConfigDto = this.configService.getDefaultConfig();

  get description(): string[] {
    return translate('extractKeywords.description');
  }

  ngOnInit() {
    this.configService.config$.pipe(untilDestroyed(this)).subscribe((c) => {
      this.config = c;
    });

    Office.context.document.addHandlerAsync(
      Office.EventType.DocumentSelectionChanged,
      () => {
        if (this.isLoading) {
          this.logging.trace({
            message: 'Already loading, ignoring doc sel change',
            severityLevel: SeverityLevel.Information,
          });
          return;
        }
        if (this.ignoreDocSelChange) {
          this.ignoreDocSelChange = false;
          return;
        }
        this.hasGenerated = false;
        this.isLoading = false;
        this.cdr.detectChanges();
      },
    );
  }

  async run() {
    this.error = '';

    try {
      this.isLoading = true;
      await this.documentService.findCitations();
      this.isLoading = false;
      this.hasGenerated = true;
    } catch (e) {
      this.error = this.officeHelperService.getErrorMessage(e);
      this.logging.trace({
        message: 'DocumentService findCitations unexpected response',
        properties: {
          err: e,
        },
      });
    }
  }

  async insertPapersAsFootnote(
    context: Word.RequestContext,
    footnoteText: string[],
  ) {
    const limitedFootnoteText = footnoteText.slice(0, 10);
    let papersData: PaperSearchResponseDto | undefined = undefined;
    try {
      papersData = await this.paperService.getPapers(
        limitedFootnoteText.join('+'),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.error = this.officeHelperService.getErrorMessage(e);
      this.logging.trace({
        message: 'PaperService getPapers unexpected response',
        properties: {
          err: e,
        },
      });
    }

    if (!papersData?.papers) {
      this.logging.trace({
        message: 'No papers found',
        severityLevel: SeverityLevel.Error,
      });

      return;
    }
    const selection = context.document.getSelection();
    await this.paperService.insertPapersAsFootnote(
      selection,
      context,
      papersData.papers,
    );
  }

  constructor(
    private openAIApiService: OpenAIApiService,
    private configService: ConfigService,
    private paperService: PaperProcessingService,
    private officeHelperService: OfficeHelperService,
    private cdr: ChangeDetectorRef,
    private logging: LoggingService,
    private documentService: DocumentService,
  ) {}
}
